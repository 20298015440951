import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, CardBody, CardGroup, FormGroup, Input, Label } from 'reactstrap'
import { updateEntities } from 'redux-query'
import { useMutation } from 'redux-query-react'

import logo from 'assets/images/logo-blue.png'

import { signIn } from 'api'
import { FormServerErrors } from 'components/FormServerErrors'
import AuthFluidDisplay from './AuthFluidDisplay'

const LoginPage = () => {
  const [errors, setErrors] = useState([] as string[])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [showTwoFactor, setShowTwoFactor] = useState(false)
  const [submitCount, setSubmitCount] = useState(0)
  const dispatch = useDispatch()
  const [{ isPending }, doMutation] = useMutation(() => {
    return signIn(
      {
        credentials: {
          email: email,
          password: password,
          code: showTwoFactor ? code : undefined,
        },
      },
      {
        force: true,
        transform: (body) => {
          const hubspotVisitorToken = { visitorToken: body.data?.hubspotVisitorToken }
          return { account: body.data, hubspot: hubspotVisitorToken }
        },
        update: {
          account: (_, newValue) => newValue,
          hubspot: (_, newValue) => newValue,
        },
      },
    )
  })

  const handleSubmit = () => {
    if (!email || !password) {
      setErrors(['Please enter your email and password.'])
      return
    }
    doMutation()?.then((response) => {
      switch (response.status) {
        case 400:
          setShowTwoFactor(true)
          setSubmitCount(submitCount + 1)
          if (submitCount > 0) {
            setErrors(response.body?.errors || [])
          } else {
            setErrors([])
          }
          break
        case 401:
          setErrors(response.body?.errors || [])
          break
      }
    })
  }

  useEffect(() => {
    const onKeydown = (e) => {
      if (e.key === 'Enter') handleSubmit()
    }
    window.addEventListener('keydown', onKeydown)
    return () => window.removeEventListener('keydown', onKeydown)
  })

  const cancelLogin = () => {
    setErrors([])
    setShowTwoFactor(false)
    setSubmitCount(0)
    dispatch(
      updateEntities({
        account: (_) => undefined,
        loginError: (_) => undefined,
      }),
    )
  }

  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <CardGroup className="align-items-center d-flex h-100">
          <CardBody>
            <div className="auth-brand text-center text-lg-left">
              <img src={logo} alt="logo" height="25" />
            </div>
            <h2 className="mt-0">Sign In</h2>
            <p className="text-muted mb-4">Enter your email address and password to access your account.</p>
            <FormServerErrors errors={errors} />

            {showTwoFactor ? (
              <FormGroup>
                <Label>Two-factor Verification Code</Label>
                <Input
                  onChange={(e) => setCode(e.target.value)}
                  name="code"
                  placeholder="6 digits"
                  type="text"
                  required={showTwoFactor}
                  autoFocus
                  tabIndex={1}
                />
                <Button
                  className="btn-rotessa-secondary min-width mr-1 mt-4"
                  disabled={isPending}
                  onClick={cancelLogin}
                  tabIndex={3}
                >
                  Cancel
                </Button>
                <Button disabled={isPending} onClick={handleSubmit} color="primary" className="mt-4" tabIndex={2}>
                  Continue
                </Button>
              </FormGroup>
            ) : (
              <>
                <FormGroup>
                  <Label>Email Address</Label>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    type="text"
                    placeholder="Enter your email"
                    required
                    autoFocus
                    tabIndex={1}
                  />
                </FormGroup>
                <FormGroup>
                  <Link to="/forgot_password" className="text-muted float-right" tabIndex={4}>
                    <p className="mb-0">Reset your password</p>
                  </Link>
                  <Label>Password</Label>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    placeholder="Enter your password"
                    type="password"
                    required
                    tabIndex={2}
                  />
                </FormGroup>
                <Button
                  disabled={isPending}
                  onClick={handleSubmit}
                  color="primary"
                  className="mt-4 mb-2 btn-block"
                  tabIndex={3}
                >
                  <i className="mdi mdi-login mr-2" />
                  Sign In
                </Button>
              </>
            )}
            <footer className="footer footer-alt">
              <p className="text-muted">
                Don't have an account? <Link to="/create_account">Signup</Link>
              </p>
            </footer>
          </CardBody>
        </CardGroup>
      </div>
      <AuthFluidDisplay />
    </div>
  )
}

export default LoginPage
