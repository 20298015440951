// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RolePermission,
    RolePermissionFromJSON,
    RolePermissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User  {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mobilePhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastSignInAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    customerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    requiresReset?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role?: string;
    /**
     * 
     * @type {RolePermission}
     * @memberof User
     */
    rolePermission?: RolePermission;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    roleId?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    clientId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    twoFactorAuthentication?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    rotessaStaff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    showNewFeatureTour?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    redirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    hubspotVisitorToken?: string;
}

export function UserFromJSON(json: any): User {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobilePhone': !exists(json, 'mobile_phone') ? undefined : json['mobile_phone'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'lastSignInAt': !exists(json, 'last_sign_in_at') ? undefined : new Date(json['last_sign_in_at']),
        'customerId': !exists(json, 'customer_id') ? undefined : json['customer_id'],
        'requiresReset': !exists(json, 'requires_reset') ? undefined : json['requires_reset'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'publicId': !exists(json, 'public_id') ? undefined : json['public_id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'rolePermission': !exists(json, 'role_permission') ? undefined : RolePermissionFromJSON(json['role_permission']),
        'roleId': !exists(json, 'role_id') ? undefined : json['role_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'twoFactorAuthentication': !exists(json, 'two_factor_authentication') ? undefined : json['two_factor_authentication'],
        'rotessaStaff': !exists(json, 'rotessa_staff') ? undefined : json['rotessa_staff'],
        'showNewFeatureTour': !exists(json, 'show_new_feature_tour') ? undefined : json['show_new_feature_tour'],
        'redirectUrl': !exists(json, 'redirect_url') ? undefined : json['redirect_url'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'hubspotVisitorToken': !exists(json, 'hubspot_visitor_token') ? undefined : json['hubspot_visitor_token'],
    };
}

export function UserToJSON(value?: User): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'user_id': value.userId,
        'email': value.email,
        'mobile_phone': value.mobilePhone,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'last_sign_in_at': value.lastSignInAt === undefined ? undefined : value.lastSignInAt.toISOString(),
        'customer_id': value.customerId,
        'requires_reset': value.requiresReset,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'name': value.name,
        'public_id': value.publicId,
        'role': value.role,
        'role_permission': RolePermissionToJSON(value.rolePermission),
        'role_id': value.roleId,
        'client_id': value.clientId,
        'two_factor_authentication': value.twoFactorAuthentication,
        'rotessa_staff': value.rotessaStaff,
        'show_new_feature_tour': value.showNewFeatureTour,
        'redirect_url': value.redirectUrl,
        'position': value.position,
        'hubspot_visitor_token': value.hubspotVisitorToken,
    };
}


