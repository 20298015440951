import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'

import { AvForm } from 'availity-reactstrap-validation'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { updateBankAccount, UpdateBankAccount, uploadClientFile } from 'api'
import { CLIENT_MAX_FILE_SIZE } from 'app/constants'
import { getActiveClient } from 'app/selectors'
import { BankAccountFields } from 'components/BankAccountFields'
import { CenterContent } from 'components/CenterContent'
import { SmartFormServerErrors } from 'components/FormServerErrors'
import { getFileErrorMessages, MinimalFileUploader } from 'components/MinimalFileUploader'
import { Errors } from 'hooks/useFormServerErrors'
import { capitalize } from 'utilities'

const SETTLEMENT_ACCOUNT_VOID_CHEQUE = 'Settlement Void Cheque or Account Auth'
const BILLING_ACCOUNT_VOID_CHEQUE = 'Billing Void Cheque or Account Auth'
const SETTLEMENT_ACCOUNT_RECENT_BANK_STATEMENT = 'Settlement Recent Bank Statement'
const BILLING_ACCOUNT_RECENT_BANK_STATEMENT = 'Billing Recent Bank Statement'
const SETTLEMENT_DESCRIPTION = 'to verify that the settlement account matches your business name and details on file with Rotessa. This ensures secure transactions and prevents fraud.'
const BILLING_DESCRIPTION = 'to verify that the billing account matches the details on file with Rotessa. This ensures accurate billing, prevents unauthorized changes, and maintains account security.'

export const ClientBankAccountForm = () => {
  const history = useHistory()
  const activeClient = useSelector(getActiveClient) || {}
  const [serverErrors, setServerErrors] = useState<Errors>()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const formRef = useRef<AvForm>()
  const [selectedChequeFile, setSelectedChequeFile] = useState<File>()
  const [selectedBankStatementFile, setSelectedBankStatementFile] = useState<File>()
  const [uploadErrors, setUploadErrors] = useState({} as Errors)
  const location = useLocation()
  const bankAccountType = location.pathname.includes('billing') ? 'billing_bank_account' : 'settlement_bank_account'
  const bankAccountName = bankAccountType === 'billing_bank_account' ? 'billing' : 'settlement'
  const voidCheque = useMemo(() => bankAccountName === 'billing' ? BILLING_ACCOUNT_VOID_CHEQUE : SETTLEMENT_ACCOUNT_VOID_CHEQUE, [bankAccountName])
  const recentBankStatement = useMemo(() => bankAccountName === 'billing' ? BILLING_ACCOUNT_RECENT_BANK_STATEMENT : SETTLEMENT_ACCOUNT_RECENT_BANK_STATEMENT, [bankAccountName])
  const description = useMemo(() => bankAccountName === 'billing' ? BILLING_DESCRIPTION : SETTLEMENT_DESCRIPTION, [bankAccountName])
  const [fileMutationState, doFileMutation] = useMutation((id: number, file: File, title: string) =>
    uploadClientFile({
      id: id,
      file: file,
      title: title,
    }),
  )
  const [bankAccountMutationState, doBankAccountMutation] = useMutation((values: UpdateBankAccount) => {
    return updateBankAccount({
      id: activeClient.id,
      updateBankAccount: values,
    })
  })

  function handleValidSubmit(_event, values: UpdateBankAccount) {
    if (!!activeClient?.id) {
        const errors = {}
        if (!selectedChequeFile) errors[voidCheque] = ['Void cheque or account authorization is required.']
        if (!selectedBankStatementFile) errors[recentBankStatement] = ['Recent bank statement is required.']

        if (Object.keys(errors).length > 0) {
          setUploadErrors(errors)
        }
        else if (!!selectedChequeFile && !!selectedBankStatementFile) {
          doFileMutation(activeClient.id, selectedChequeFile, voidCheque)?.then((response) => {
            if (response?.body?.status !== 200) 
              setUploadErrors((prev) => ({ ...prev, [voidCheque]: response?.body?.messages?.file || response?.body?.errors || ['Unable to upload file.'] }))
            else {
              doFileMutation(activeClient.id, selectedBankStatementFile, recentBankStatement)?.then((response) => {
                if (response?.body?.status !== 200) 
                  setUploadErrors((prev) => ({ ...prev, [recentBankStatement]: response?.body?.messages?.file || response?.body?.errors || ['Unable to upload file.'] }))
                else {
                  values.accountType = bankAccountType
                  doBankAccountMutation(values)?.then((response) => {
                    setServerErrors(response?.body?.messages)
                    if (response?.body?.status < 400) {
                      setShowSuccessModal(true)
                    }
                  })
                }
              })
            }
          })
        }
    }
  }

  const handleChangeFile = (file: File, accepted: boolean, setAcceptedFile: (file: File) => void, title: string) => {
    if (accepted) {
      setAcceptedFile(file)
      setUploadErrors((prev) => {
        const errors = { ...prev }
        delete errors[title]
        return errors
      })
    } else {
      setUploadErrors((prev) => ({ ...prev, [title]: [getFileErrorMessages(file, CLIENT_MAX_FILE_SIZE)] || [] }))
    }
  }

  const isPending = useMemo(() => bankAccountMutationState.isPending || fileMutationState.isPending, 
    [bankAccountMutationState, fileMutationState])

  return (
    <div>
      <Modal isOpen={showSuccessModal}>
        <ModalHeader>Success</ModalHeader>
        <ModalBody>Your {bankAccountName} bank account has been updated.</ModalBody>
        <ModalFooter className="text-right">
          <Button color="primary" onClick={() => history.push('/client/settings')} className="ml-2">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <CenterContent>
        <div className="page-title-box">
          <h4 className="page-title">Update {capitalize(bankAccountName)} Account</h4>
        </div>
        <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
        <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} ref={formRef}>
          <Card>
            <CardBody>
              <p>
                Update your {bankAccountName} account details by verifying the current account here, then enter the new
                account information below.
              </p>
              <h4 className="header-title mb-3">Current Account</h4>
              <BankAccountFields fieldNamePrefix="currentAccount" countryCode={activeClient?.countryCode} />
              <h4 className="header-title mb-3 mt-4">New Account</h4>
              <BankAccountFields
                fieldNamePrefix="newAccount"
                countryCode={activeClient?.countryCode}
                serverErrors={serverErrors}
                startingTabIndex={3}
              />
              <h4 className="header-title mb-3 mt-4">New Account Files</h4>
              <p>We require a <b>void cheque or account authorization</b> and a <b>recent bank statement</b> {description} If you have questions, please contact <a href="mailto:support@rotessa.com">support@rotessa.com</a>.</p>
              <div>
                <Row className="mt-3 mb-2">
                  <Col>
                    <MinimalFileUploader
                      onFileUpload={(file, accepted) => {
                        handleChangeFile(file, accepted, setSelectedChequeFile, voidCheque)
                      }}
                      accept=".jpeg, .jpg, .pdf, .png"
                      showPreview={true}
                      maxFiles={1}
                      disabled={isPending}
                      maxSize={CLIENT_MAX_FILE_SIZE}
                      uploadText="Upload a Void Cheque"
                    />
                    <Row>
                      {uploadErrors[voidCheque] && (
                        <Col className="invalid-feedback d-block">
                          {uploadErrors[voidCheque][0]}
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3 mb-2">
                  <Col>
                    <MinimalFileUploader
                      onFileUpload={(file, accepted) => {
                        handleChangeFile(file, accepted, setSelectedBankStatementFile, recentBankStatement)
                      }}
                      accept=".jpeg, .jpg, .pdf, .png"
                      showPreview={true}
                      maxFiles={1}
                      disabled={isPending}
                      maxSize={CLIENT_MAX_FILE_SIZE}
                      uploadText="Upload a Recent Bank Statement"
                    />
                    <Row>
                      {uploadErrors[recentBankStatement] && (
                        <Col className="invalid-feedback d-block">{uploadErrors[recentBankStatement][0]}</Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <div className="text-right">
            <Link to="/client/settings">
              <Button disabled={isPending} className="btn-rotessa-secondary min-width mb-2 ml-1" tabIndex={7}>
                Close
              </Button>
            </Link>
            <Button disabled={isPending} color="primary" className="mb-2 ml-1" tabIndex={8}>
              Submit
            </Button>
          </div>
        </AvForm>
      </CenterContent>
    </div>
  )
}
