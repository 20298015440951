import { CLIENT_ROUTE, LOGGED_IN_ROUTES, STUDENT_ROUTE } from 'app/routes'
import { getActiveUser } from 'app/selectors'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

declare global {
  interface Window {
    HubSpotConversations: {
      clear: (options: { resetWidget: boolean }) => void
      widget: {
        status: () => { loaded: boolean; pending: boolean }
        load: () => void
        remove: () => void
        open: () => void
        close: () => void
        refresh: () => void
      }
    }
    hsConversationsSettings: {
      loadImmediately: boolean
      identificationEmail?: string
      identificationToken?: string
      loadedPreviously?: boolean
    }
    hsConversationsOnReady: (() => void)[]
  }
}
export const HubSpotConversations = () => {
  const activeUser = useSelector(getActiveUser)
  const hubspotToken = useMemo(() => activeUser?.hubspotVisitorToken, [activeUser])
  const authenticatedRoutes = [STUDENT_ROUTE, CLIENT_ROUTE].concat(LOGGED_IN_ROUTES)

  useEffect(() => {
    loadWidget()
  }, [activeUser?.email, hubspotToken])

  const loadWidget = () => {
    if (!!window.HubSpotConversations) {
      if (!!activeUser?.email && !!hubspotToken) {
        const userWasPreviouslyNotLoggedIn =
          !window?.hsConversationsSettings?.identificationEmail && window?.hsConversationsSettings?.loadedPreviously
        const tokenHasChanged = window?.hsConversationsSettings?.identificationToken !== hubspotToken

        if (userWasPreviouslyNotLoggedIn || tokenHasChanged) {
          // if you were previously logged in, but now you are not...
          window.HubSpotConversations.clear({ resetWidget: true })
        }

        window.hsConversationsSettings = {
          loadImmediately: false,
          identificationEmail: activeUser?.email,
          identificationToken: hubspotToken,
          loadedPreviously: true,
        }

        window.HubSpotConversations.widget.load()
      }
      // We handle hiding the chat on certain routes (authorize/donation/link_auth/email_auth/microdeposit_verification) on HubSpot chatflow targetting
      else if (!authenticatedRoutes.some((v) => window.location.pathname.includes(v.path))) {
        const userWasPreviouslyLoggedIn = !!window?.hsConversationsSettings?.identificationEmail
        if (userWasPreviouslyLoggedIn) {
          // if you were previously logged in, but now you are not..
          window.HubSpotConversations.clear({ resetWidget: true })
        }

        window.hsConversationsSettings = {
          loadImmediately: false,
          loadedPreviously: true,
        }
        window.HubSpotConversations.widget.load()
      }
    }
  }

  window.hsConversationsOnReady = [loadWidget]

  return <></>
}
